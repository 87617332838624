<template>
  <v-app>
    <app-header />

    <v-main cl>
      <router-view/>
    </v-main>
    <app-footer />
  </v-app>
</template>

<script>
import AppFooter from './components/core/AppFooter.vue';
import AppHeader from './components/core/AppHeader.vue';

export default {
  name: 'App',

  components: {
    // AppHeader,
    AppFooter,
    AppHeader
  },

  data: () => ({
    //
  }),
};
</script>

<style>
@font-face {
  font-family: "Khula";
  src: url(./assets/fonts/khula/Khula-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Khula";
  src: url(./assets/fonts/khula/Khula-ExtraBold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Khula";
  src: url(./assets/fonts/khula/Khula-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

/*
.container {
  max-width: 90% !important;
}
*/
</style>
