<template>
  <v-container fluid class="px-12">
    <v-row class="text-left">
      <v-col md="6" cols="12" class="pa-12">
        <v-img src="team.png" class="d-none d-md-block"/>
      </v-col>
      <v-col md="6" cols="12" class="pa-12 d-none d-md-block">
        <h5 class="text-h6 py-2">{{ $t('about.HEADER') }}</h5>
        <h2 class="text-h4 primary--text py-2">{{ $t('about.TITLE') }}</h2>
        <p>{{ $t('about.SUBTITLE') }}</p>
        <join-button />
      </v-col>
      <v-col md="6" cols="12" class="pa-4 d-md-none">
        <h5 class="text-h6 py-2">{{ $t('about.HEADER') }}</h5>
        <h2 class="text-h4 primary--text py-2">{{ $t('about.TITLE') }}</h2>
        <p>{{ $t('about.SUBTITLE') }}</p>
        <join-button />
      </v-col>
    </v-row>
    <donate-button/>
  </v-container>
</template>

<script>
import DonateButton from '../components/common/DonateButton'
import JoinButton from '../components/common/JoinButton'

  export default {
    name: 'AboutUs',

    components: {
      DonateButton,
      JoinButton
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
</style>