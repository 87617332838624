<template>
  <v-container fluid class="d-none d-md-block">
    <v-row class="text-left mx-12" justify="center">
      <v-col cols="10" class="text-center">
        <h2 class="text-h2 primary--text pa-12">{{ $t('currentNews.HEADER') }}</h2>
      </v-col>
      <v-col md="8" cols="12">
        <v-card class="pa-4">
          <v-img src="@/assets/1.png" class="pa-n4" />
          <h5 class="px-4 py-2 pt-4">March 25 2022</h5>
          <h4 class="px-4 py-2">French volunteers rally to Zelenskyy’s call to join war</h4>
          <p class="px-4 py-2">“Zelenskyy’s call inspired us to leave,” said Joe, a 48-year-old driver who showed up with five other men at the Ukrainian Embassy in Paris on Tuesday. “I don’t really have any animosity against the Russians … I’m going there to defend human beings, and because I have an 8-year-old child … for him, I just can’t let this happen so close to us.” Following Russia’s invasion of Ukraine last week, French authorities have not said publicly how many people have responded to Ukraine’s call to arms.</p>
        </v-card>
      </v-col>
      <v-col md="4" cols="12">
        <v-row class="text-center" align="center">
          <v-col v-for="article in articles" :key="article.title" md="12" cols="12">
            <v-card class='my-2'>
              <v-row>
                <v-col md="6" cols="12">
                  <v-img width="180px" :src="article.thumbnailImage" class="pa-n2 ml-2" />
                </v-col>
                <v-col md="6" cols="12">
                  <h5 class="px-4 py-2">{{ article.date }}</h5>
                  <h4 class="px-4 py-2">{{ article.title }}</h4>
                  <p class="px-4 py-2 text-truncate">{{ article.body }}</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'CurrentNews',

    data: () => ({
      articles: [
        {
          thumbnailImage: "/img/articles/2.png",
          featuredImage:  "@/assets/1.png",
          date: "March 25 2022",
          title: "French volunteers rally to Zelenskyy’s call to join war",
          body: "Zelenskyy’s call inspired us to leave,” said Joe, a 48-year-old driver who showed up with five other men at the Ukrainian Embassy in Paris on Tuesday. “I don’t really have any animosity against the Russians … I’m going there to defend human beings, and because I have an 8-year-old child … for him, I just can’t let this happen so close to us.” Following Russia’s invasion of Ukraine last week, French authorities have not said publicly how many people have responded to Ukraine’s call to arms."
        },
        {
          thumbnailImage: "/img/articles/3.png",
          featuredImage:  "@/assets/1.png",
          date: "March 25 2022",
          title: "French volunteers rally to Zelenskyy’s call to join war",
          body: "Zelenskyy’s call inspired us to leave,” said Joe, a 48-year-old driver who showed up with five other men at the Ukrainian Embassy in Paris on Tuesday. “I don’t really have any animosity against the Russians … I’m going there to defend human beings, and because I have an 8-year-old child … for him, I just can’t let this happen so close to us.” Following Russia’s invasion of Ukraine last week, French authorities have not said publicly how many people have responded to Ukraine’s call to arms."
        },
        {
          thumbnailImage: "/img/articles/4.png",
          featuredImage:  "@/assets/1.png",
          date: "March 25 2022",
          title: "French volunteers rally to Zelenskyy’s call to join war",
          body: "Zelenskyy’s call inspired us to leave,” said Joe, a 48-year-old driver who showed up with five other men at the Ukrainian Embassy in Paris on Tuesday. “I don’t really have any animosity against the Russians … I’m going there to defend human beings, and because I have an 8-year-old child … for him, I just can’t let this happen so close to us.” Following Russia’s invasion of Ukraine last week, French authorities have not said publicly how many people have responded to Ukraine’s call to arms."
        },
        {
          thumbnailImage: "/img/articles/5.png",
          featuredImage:  "@/assets/1.png",
          date: "March 25 2022",
          title: "French volunteers rally to Zelenskyy’s call to join war",
          body: "Zelenskyy’s call inspired us to leave,” said Joe, a 48-year-old driver who showed up with five other men at the Ukrainian Embassy in Paris on Tuesday. “I don’t really have any animosity against the Russians … I’m going there to defend human beings, and because I have an 8-year-old child … for him, I just can’t let this happen so close to us.” Following Russia’s invasion of Ukraine last week, French authorities have not said publicly how many people have responded to Ukraine’s call to arms."
        }
      ]
    }),
    computed: {
      myProperty: function () {
          return this.$t('propertyName')
      }
    }
  }
</script>
