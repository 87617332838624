<template>
  <div>
    <hero-banner/>
    <about-us />
    <our-mission />
    <donate-banner />
    <we-need />
    <poland-office />
    <!-- <official-partners /> -->
    <current-news />
    <our-partners />
    <join-us />
  </div>
</template>

<script>
  import HeroBanner from '../components/HeroBanner'
  import AboutUs from '../components/AboutUs'
  import OurMission from '../components/OurMission'
  import DonateBanner from '../components/DonateBanner'
  import WeNeed from '../components/WeNeed'
  import PolandOffice from '../components/PolandOffice'
  // import OfficialPartners from '../components/OfficialPartners'
  import CurrentNews from '../components/CurrentNews'
  import OurPartners from '../components/OurPartners'
  import JoinUs from '../components/JoinUs'

  export default {
    name: 'HomePage',

    components: {
      HeroBanner,
      AboutUs,
      OurMission,
      DonateBanner,
      WeNeed,
      PolandOffice,
      // OfficialPartners,
      CurrentNews,
      OurPartners,
      JoinUs
    },
  }
</script>
