<template>
  <v-container fluid>
    <v-row class="text-center primary bg py-12">
      <v-col cols="12 py-12">
        <br />
        <br />
        <h2 class="py-4 white--text">{{ $t('joinUs.HEADER') }}<span class="secondary--text">{{ $t('joinUs.HEADER2') }}</span>{{ $t('joinUs.HEADER3') }}</h2>
        <h5 class="py-4 white--text">{{ $t('joinUs.TITLE') }}</h5>
        <br />
        <join-button />
        <br />
        <br />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import JoinButton from '../components/common/JoinButton'

  export default {
    name: 'JoinUs',

    data: () => ({
    }),
    components: {
      JoinButton
    }
  }
</script>

<style scoped>
.bg {
  height: "800";
  background-image: url("~@/assets/img/backgrounds/lady.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>