<template>
<v-container fluid>
    <v-row class="text-left primary bg" align="center">
      <v-col md="6" cols="12" class="pa-12 d-none d-md-block">
        <h2 class="white--text text-h2">{{ $t('donate.HEADER') }}</h2>
        <br />
        <v-dialog
          v-model="dialog"
          persistent
          max-width="400px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary primary--text ma-4 yellow-button"
              v-bind="attrs"
              v-on="on"
            >
              Donate
            </v-btn>
          </template>
          <v-card>
            <iframe src="https://donorbox.org/embed/support-mt6-s-efforts-for-ukraine" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 250px; max-height:none!important"></iframe>
            <v-card-actions>
            <v-spacer>
            </v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false" > Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col md="6" cols="12" class="mt-8 pa-12 d-md-none text-center">
        <h2 class="white--text text-h2">{{ $t('donate.HEADER') }}</h2>
        <br />
        <v-dialog
          v-model="dialog"
          persistent
          max-width="400px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary primary--text ma-4 yellow-button"
              v-bind="attrs"
              v-on="on"
            >
              Donate
            </v-btn>
          </template>
          <v-card>
            <iframe src="https://donorbox.org/embed/support-mt6-s-efforts-for-ukraine" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 250px; max-height:none!important"></iframe>
            <v-card-actions>
            <v-spacer>
            </v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false" > Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col md="6" cols="12" class="pa-12" id="contact">
        <v-card dark class="primary pa-12">
          <h2 class="white--text text-h4 py-4">{{ $t('donate.FORM_TITLE') }}<span class="secondary--text">{{ $t('donate.FORM_TITLE2') }}</span></h2>
          <v-form
            ref="form"
            action="https://formspree.io/f/xgedlbnv"
            method="POST"
            v-model="valid"
            lazy-validation
            dark
          >
            <v-text-field
              v-model="name"
              :counter="50"
              :rules="nameRules"
              :label="$t('forms.NAME')"
              required
              type="name"
              name="name"
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="$t('forms.EMAIL')"
              required
              type="email"
              name="email"
            ></v-text-field>

            <!-- <v-select
              v-model="select"
              :items="items"
              :rules="[v => !!v || 'Item is required']"
              :label="$t('forms.ITEM')"
              required
            ></v-select> -->

            <v-textarea
              v-model="message"
              :label="$t('forms.MESSAGE')"
              required
              type="message"
              name="message"
            ></v-textarea>

            <v-btn
              :disabled="!valid"
              color="white"
              class="mr-4 primary--text"
              @click="validate"
              type="submit"
            >
              SEND A MESSAGE
            </v-btn>
<!-- 
            <v-btn
              color="error"
              class="mr-4"
              @click="reset"
            >
              {{ $t('forms.RESET_FORM') }}
            </v-btn>

            <v-btn
              color="warning"
              @click="resetValidation"
            >
              {{ $t('forms.REST_VALIDATION') }}
            </v-btn> -->
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'DonateBanner',
    
    data: () => ({
      publicPath: process.env.BASE_URL,
      dialog: false,
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 50) || 'Name must be less than 10 characters',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      select: null,
      items: [
        'Donations',
        'Item 2',
        'Item 3',
        'Item 4',
      ],
      message: '',
    }),

    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>

<style scoped>
  .yellow-button {
    background-image: linear-gradient(180deg, #FDFF94 0%, #DFC900 100%);
  }
</style>
<style scoped>
.bg {
  background-image: url("~@/assets/img/backgrounds/inspireHope.png");
  background-repeat: no-repeat;
  background-size: stretch;
}
</style>