<template>
  <v-container fluid class="primary">
    <v-footer
      dark
      padless
      class="primary"
    >
    <br />
      <v-row justify="right" align="center">
        <v-col md="3" cols="12">
          <v-row class="text-center my-4">
            <v-spacer />
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              src="dovelogo.png"
              transition="scale-transition"
              width="80"
            />
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              src="logo.png"
              transition="scale-transition"
              width="120"
            />
            <v-spacer />
          </v-row>

        </v-col>
        <v-col md="6" cols="12" class="d-none d-md-block">
          <v-row class="text-center" align="center">
            <v-col md="4" cols="12">
              <v-row>
                  <a href="mailto:team@mtsix.org" target="_blank" class="noDecoration">
                    <v-icon class="px-4">mdi-mail</v-icon>
                  </a>
                  <a href="mailto:team@mtsix.org" target="_blank" class="noDecoration">
                    <h5>team@mtsix.org</h5>
                  </a>
              </v-row>
            </v-col>
            <v-col md="4" cols="12">
              <v-row align="center">
                <a href="tel:+18888181983" class="noDecoration">
                  <v-icon class="px-4">mdi-phone</v-icon>
                </a>
                <a href="tel:+18888181983" class="noDecoration">  
                  <h5>+1 (888)-818-1983</h5>
                </a>
              </v-row>
            </v-col>
            <v-col md="4" cols="12">
              <v-row align="center">
                <a href="https://goo.gl/maps/h4FG9epxnT2DYK7q6" target="_blank" class="noDecoration">
                  <v-icon class="px-4">mdi-home</v-icon>
                </a>
                <a href="https://goo.gl/maps/h4FG9epxnT2DYK7q6" target="_blank" class="noDecoration">
                  <h5>
                    1115 Broadway
                    <br />
                    New York, NY 10010
                  </h5>
                </a>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6" cols="12" class="d-md-none">
          <v-row class="text-center" justify="center" align="center">
            <v-col md="4" cols="12">
              <v-row justify="center" align="center" class="py-2">
                  <a href="mailto:team@mtsix.org" target="_blank" class="noDecoration">
                    <v-icon class="px-4">mdi-mail</v-icon>
                  </a>
                  <a href="mailto:team@mtsix.org" target="_blank" class="noDecoration">
                    <h5>team@mtsix.org</h5>
                  </a>
              </v-row>
            </v-col>
            <v-col md="4" cols="12">
              <v-row align="center" justify="center" class="py-2">
                <a href="tel:+18888181983" class="noDecoration">
                  <v-icon class="px-4">mdi-phone</v-icon>
                </a>
                <a href="tel:+18888181983" class="noDecoration">  
                  <h5>+1 (888)-818-1983</h5>
                </a>
              </v-row>
            </v-col>
            <v-col md="4" cols="12">
              <v-row align="center" justify="center">
                <a href="https://goo.gl/maps/h4FG9epxnT2DYK7q6" target="_blank" class="noDecoration">
                  <v-icon class="px-4">mdi-home</v-icon>
                </a>
                <a href="https://goo.gl/maps/h4FG9epxnT2DYK7q6" target="_blank" class="noDecoration">
                  <h5>
                    1115 Broadway
                    <br />
                    New York, NY 10010
                  </h5>
                </a>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="3" cols="12">
          <br />
          <v-row class="text-center" justify="center">
            <v-col md="2" cols="3">
              <a href="https://twitter.com/MT6Foundation" target="_blank"><v-icon class="px-4">mdi-twitter</v-icon></a>
            </v-col>
            <v-col md="2" cols="3">
              <a href="https://www.tiktok.com/@mt6foundation?loc=14&lang=en" target="_blank"><v-icon class="px-4">mdi-email</v-icon></a>
            </v-col>
            <v-col md="2" cols="3">
              <a href="https://www.instagram.com/mt6foundation/" target="_blank"><v-icon class="px-4">mdi-instagram</v-icon></a>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="d-none d-md-block">
          <v-row justify="space-around">
            <v-col md="4" cols="12">
              <p>Designed in TheRoom</p>
            </v-col>
            <v-col md="5" cols="12">
              <p>MT6 Foundation | US nonprofit | 501(c)(3) status pending | +1.888.818.1983 | EIN 88-1078670</p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="d-md-none text-center">
          <br>
          <v-row justify="space-around">
            <v-col md="5" cols="12">
              <p>MT6 Foundation | US nonprofit | 501(c)(3) status pending | +1.888.818.1983 | EIN 88-1078670</p>
            </v-col>
            <v-col md="4" cols="12">
              <p>Designed in TheRoom</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
  export default {
    name: 'AppFooter',

    data: () => ({
    }),
  }
</script>
<style scoped>
.noDecoration {
  text-decoration: none;
  color: white;
}
</style>