<template>
  <v-container fluid>
    <v-row class="text-center ma-12" align="center" justify="space-around">
      <v-col cols="12" class="pa-12">
        <h2>{{ $t('officialPartners.OUR_PARTNERS') }}</h2>
      </v-col>
      <v-col v-for="icon in icons" :key="icon.icon" md="2" cols="4">
        <v-img class="justify-center" width="200px" :src="icon.icon" />
      </v-col>
      <!-- <v-col cols="12">
        <v-row justify="center">
          <v-col v-for="icon in icons2" :key="icon.icon" md="2" cols="4">
            <v-img width="120px" :src="icon.icon" />
          </v-col>
        </v-row>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ourPartners',

    data: () => ({
    }),
    computed: {
      icons: function () {
        return [
          {
            icon: "img/partners/ministry.png",
          },
          {
            icon: "img/partners/moi.png",
          },
          {
            icon: "img/partners/verkhovna.png",
          }
        ]
      },
      icons2: function () {
        return [
        ]
      }
    }
  }
</script>

<style scoped>
html, body {
  margin:0px;
  height:100%;
}

.fillHeight {
  height: 100vh;
}
</style>
