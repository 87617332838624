// app
// export const SET_APP_VERSION = 'SET_APP_VERSION'
// locale
export const SET_LOCALE = 'SET_LOCALE'
// error
// export const SHOW_ERROR = 'SHOW_ERROR'
// export const ERROR = 'ERROR'
// // success
export const SHOW_SUCCESS = 'SHOW_SUCCESS'
export const SUCCESS = 'SUCCESS'
// // loading
// export const SHOW_LOADING = 'SHOW_LOADING'
// // auth
// export const IS_LOGGED_IN = 'IS_LOGGED_IN'
// export const SAVE_TOKEN = 'SAVE_TOKEN'
// export const SAVE_USER = 'SAVE_USER'
// export const LOGOUT = 'LOGOUT'
// export const EMAIL_VERIFIED = 'EMAIL_VERIFIED'
// export const RESET_EMAIL_SENT = 'RESET_EMAIL_SENT'
// export const SHOW_CHANGE_PASSWORD_INPUTS = 'SHOW_CHANGE_PASSWORD_INPUTS'
// // profile
// export const FILL_PROFILE = 'FILL_PROFILE'
// export const ADD_PROFILE_DATA = 'ADD_PROFILE_DATA'
// // Performances
// export const PERFORMANCES = 'PERFORMANCES'
// export const FILL_ALL_PERFORMANCES = 'FILL_ALL_PERFORMANCES'
// export const TOTAL_PERFORMANCES = 'TOTAL_PERFORMANCES'
// // Public Performances
// export const PERFORMANCES_PUBLIC = 'PERFORMANCES_PUBLIC'
// export const FILL_ALL_PERFORMANCES_PUBLIC = 'FILL_ALL_PERFORMANCES_PUBLIC'
// export const TOTAL_PERFORMANCES_PUBLIC = 'TOTAL_PERFORMANCES_PUBLIC'
// // cities
// export const FILL_ALL_CITIES = 'FILL_ALL_CITIES'
// // Admin - Cities
// export const CITIES = 'CITIES'
// export const TOTAL_CITIES = 'TOTAL_CITIES'
// // Admin - Users
// export const USERS = 'USERS'
// export const TOTAL_USERS = 'TOTAL_USERS'
