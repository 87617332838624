<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" class="py-8">
        <h2 class="py-12 px-4 text-h4 heavy">{{ $t('weNeed.HEADER') }}</h2>
      </v-col>
      <v-col cols="12">
        <v-row class="text-left">
          <v-col v-for="card in cards" :key="card.title" md="4" cols="12">
            <v-card dark v-if="card.color" :color="card.color" class="pa-4 moreRound d-flex flex-column" height="24em">
              <h2 class="text-h4 py-4">{{ card.title }}</h2>
              <p class="text-subtitle-1"><b>{{ card.subtitle }}</b></p>
              <div v-if="card.bullets">
                <div v-for="bullet in card.bullets" :key="bullet.item">
                  <p>{{ bullet.item }}</p>
                </div>
              </div>
              <v-img class="moveRight" width="160" :src="card.icon" />
            </v-card>
            <v-card v-if="!card.color" class="pa-4 moreRound d-flex flex-column" height="24em">
              <h2 class="text-h4 py-4">{{ card.title }}</h2>
              <p>{{ card.subtitle }}</p>
              <div v-if="card.bullets">
                <div v-for="bullet in card.bullets" :key="bullet.item">
                  <p>{{ bullet.item }}</p>
                </div>
              </div>
              <v-img class="moveRight" width="160" :src="card.icon" />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'WeNeed',

    data: () => ({
    }),
    computed: {
      cards: function () {
          return [
          {
            color: "primary",
            title: this.$t('weNeed.CARD1_TITLE'),
            subtitle: this.$t('weNeed.CARD1_SUBTITLE'),
            icon: "img/weNeed/cyber.png"
          },
          {
            title: this.$t('weNeed.CARD2_TITLE'),
            subtitle: this.$t('weNeed.CARD2_SUBTITLE'),
            icon: "img/weNeed/person.png"
          },
          {
            color: "primary",
            title: this.$t('weNeed.CARD3_TITLE'),
            subtitle: this.$t('weNeed.CARD3_SUBTITLE'),
            icon: "img/weNeed/map.png"
          },
          {
            title: this.$t('weNeed.CARD4_TITLE'),
            subtitle: this.$t('weNeed.CARD4_SUBTITLE'),
            icon: "img/weNeed/military.png"
          },
          {
            color: "primary",
            title: this.$t('weNeed.CARD5_TITLE'),
            subtitle: this.$t('weNeed.CARD5_SUBTITLE'),
            icon: "img/weNeed/caduseus.png",
            bullets: [
              {
                item: this.$t('weNeed.CARD5_BULLET1'),
              },
              {
                item: this.$t('weNeed.CARD5_BULLET2'),
              },
              {
                item: this.$t('weNeed.CARD5_BULLET3'),
              }
            ]
          },
          {
            title: this.$t('weNeed.CARD6_TITLE'),
            subtitle: this.$t('weNeed.CARD6_SUBTITLE'),
            icon: "img/weNeed/network.png"
          }
        ]
      }
    }
  }
</script>

<style scoped>
.heavy {
  font-weight: bolder;
}
.moreRound {
  border-radius: 12px;
}
.moveRight {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
</style>
