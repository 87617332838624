<template>
<div class="heroWrapper">
  <v-container fluid class="fillHeight">
    <v-row class="text-left primary fillHeight" align="center">
      <v-col md="6" cols="12" class="pa-12">
        <h3 class="text-h2 white--text py-4 hero-title">{{ $t('hero.title') }}<span class="secondary--text ukraine">{{ $t('hero.title2') }}</span></h3>
        <h4 class="py-4 white--text text-h4 hero-subtitle">{{ $t('hero.subtitle') }}</h4>
        <v-dialog
          v-model="dialog"
          persistent
          max-width="400px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary primary--text yellow-button"
              class="py-4"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('common.donate') }}
            </v-btn>
          </template>
          <v-card>
            <iframe src="https://donorbox.org/embed/support-mt6-s-efforts-for-ukraine" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 250px; max-height:none!important"></iframe>
            <v-card-actions>
            <v-spacer>
            </v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false" > Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col md="6" cols="12" class="pa-12">
        <!-- Temporarily commenting out video portion for 1.0 rollout -->
        <!--<h2>{{ $t('common.watch') }}</h2> -->
        <v-img class="ukraine-graphic" 
        src="../assets/images/ukraine-outline.png"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>

<script>
  export default {
    name: 'HeroBanner',

    data: () => ({
      dialog: false
    }),
  }
</script>

<style scoped>
html, body {
  margin:0px;
  height:100%;
}

.heroWrapper {
  background-color: #051255 !important;
}

.fillHeight {
  height: 100vh;
}

.hero-title {
  font-family: "Khula" !important;
  font-weight: 800 !important;
}

.hero-subtitle {
  font-family: "Khula" !important;
  font-weight: 400 !important;
  font-size: 20px !important; 
  line-height: 28px !important;
}

span.ukraine {
  color: #FDFF94 !important;
}

.yellow-button {
 background-image: linear-gradient(180deg, #FDFF94 0%, #DFC900 100%) !important;
 font-family: "Khula" !important;
 font-weight: 800 !important;
 letter-spacing: -.25px !important;
 min-width: 115px !important;
}

</style>
