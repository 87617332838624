<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1200px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="secondary primary--text yellow-button"
        class="py-4 secondary primary--text bigLetters"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('joinUs.JOIN_US') }}
      </v-btn>
    </template>
    <v-card class="pa-12">
      <validation-observer
          ref="observer"
      >
        <form 
          action="https://formspree.io/f/xwkynjdl"
          method="POST" 
        >
          <v-row align="start">
            <v-col cols=12>
              <h2>{{ $t('joinUs.JOIN_US') }}</h2>
            </v-col> 
            <v-col cols=12>
              <h4>{{ $t('joinUs.POSITION') }}</h4>
              <v-row>
                <v-col md="2" cols="12">
                  <v-checkbox
                    v-model="checkbox1"
                    :label="$t('joinUs.MAJOR_DONOR')"
                    type="checkbox"
                    name="Major Donor"
                  ></v-checkbox>
                </v-col>
                <v-col md="2" cols="12">
                  <v-checkbox
                    v-model="checkbox2"
                    :label="$t('joinUs.VOLUNTEER')"
                    type="checkbox"
                    name="Volunteer"
                  ></v-checkbox>
                </v-col>
                <v-col md="2" cols="12">
                  <v-checkbox
                    v-model="checkbox3"
                    :label="$t('joinUs.SUPPLIER')"
                    type="checkbox"
                    name="Supplier"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="6" cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="Name"
                rules="required|max:50"
              >
                <v-text-field
                  v-model="name"
                  :counter="50"
                  :error-messages="errors"
                  label="Name"
                  type="name"
                  name="name"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="phoneNumber"
                :rules="{
                  required: true,
                }"
              >
                <v-text-field
                  v-model="phoneNumber"
                  :error-messages="errors"
                  label="Phone Number"
                  type="phone"
                  name="phone"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required|email"
              >
                <v-text-field
                  v-model="email"
                  :error-messages="errors"
                  label="E-mail"
                  type="email"
                  name="email"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="message"
                rules="required"
              >
                <v-textarea
                  v-model="message"
                  :error-messages="errors"
                  label="Message"
                  type="message"
                  name="message"
                  required
                ></v-textarea>
              </validation-provider>
            </v-col>
            <v-col cols="6" class="d-none d-md-block">
              <v-row>
                <v-col cols="12">
                  <v-row class="text-center" justify="center" align="center">
                    <v-col cols="12">
                      <v-row justify="center" align="center" class="py-2">
                          <a href="mailto:team@mtsix.org" target="_blank" class="noDecoration">
                            <v-icon class="px-4">mdi-mail</v-icon>
                          </a>
                          <a href="mailto:team@mtsix.org" target="_blank" class="noDecoration">
                            <h5>team@mtsix.org</h5>
                          </a>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row align="center" justify="center" class="py-2">
                        <a href="tel:+18888181983" class="noDecoration">
                          <v-icon class="px-4">mdi-phone</v-icon>
                        </a>
                        <a href="tel:+18888181983" class="noDecoration">  
                          <h5>+1 (888)-818-1983</h5>
                        </a>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row align="center" justify="center">
                        <a href="https://goo.gl/maps/h4FG9epxnT2DYK7q6" target="_blank" class="noDecoration">
                          <v-icon class="px-4">mdi-home</v-icon>
                        </a>
                        <a href="https://goo.gl/maps/h4FG9epxnT2DYK7q6" target="_blank" class="noDecoration">
                          <h5>
                            1115 Broadway
                            <br />
                            New York, NY 10010
                          </h5>
                        </a>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <br />
                  <v-row class="text-center noDecoration" justify="center">
                    <v-col md="2" cols="3">
                      <a href="https://twitter.com/MT6Foundation" target="_blank"><v-icon class="px-4">mdi-twitter</v-icon></a>
                    </v-col>
                    <v-col md="2" cols="3">
                      <a href="https://www.tiktok.com/@mt6foundation?loc=14&lang=en" target="_blank"><v-icon class="px-4">mdi-email</v-icon></a>
                    </v-col>
                    <v-col md="2" cols="3">
                      <a href="https://www.instagram.com/mt6foundation/" target="_blank"><v-icon class="px-4">mdi-instagram</v-icon></a>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4 my-2"
            type="submit"
          >
            submit
          </v-btn>
          <v-btn @click="clear" class="mr-4 my-2">
            clear
          </v-btn>
          <v-btn @click="dialog=false" class="my-2">
            close
          </v-btn>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
  import { required, email, max, } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

  setInteractionMode('eager')

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data: () => ({
      name: '',
      dialog: false,
      phoneNumber: '',
      email: '',
      message: '',
      checkbox1: '',
      checkbox2: '',
      checkbox3: '',
      select: null,
      items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
      ],
      checkbox: null,
    }),

    methods: {
      clear () {
        this.name = ''
        this.phoneNumber = ''
        this.email = ''
        this.select = null
        this.checkbox = null
        this.$refs.observer.reset()
      },
    },
  }
</script>

<style scoped>
a:link { text-decoration: none; }
.bigLetters {
  font-family: "Khula";
  font-weight: 800 !important;
  font-size: 14px !important;
  letter-spacing: -.25px;
}
</style>