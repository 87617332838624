<template>
  <v-container fluid class="px-12">
    <v-row class="text-left">
      <v-col md="6" cols="12" class="pa-12 my-8 d-none d-md-block">
        <h2 class="text-h4 primary--text py-4">{{ $t('polandOffice.HEADER') }}</h2>
        <p class="text-h6">{{ $t('polandOffice.TITLE') }}</p>
      </v-col>
      <v-col md="6" cols="12" class="pa-4 my-8 d-md-none">
        <h2 class="text-h4 primary--text py-4">{{ $t('polandOffice.HEADER') }}</h2>
        <p class="text-h6">{{ $t('polandOffice.TITLE') }}</p>
      </v-col>
      <v-col md="6" cols="12" class="pa-12 my-8 d-none d-md-block">
        <h2 class="text-h4 primary--text py-4">{{ $t('slovakianOffice.HEADER') }}</h2>
        <p class="text-h6">{{ $t('slovakianOffice.TITLE') }}</p>
      </v-col>
      <v-col md="6" cols="12" class="pa-4 my-8 d-md-none text-right">
        <h2 class="text-h4 primary--text py-4">{{ $t('slovakianOffice.HEADER') }}</h2>
        <p class="text-h6">{{ $t('slovakianOffice.TITLE') }}</p>
      </v-col>
      <!-- <v-col md="6" cols="12" class="pa-12 my-8 d-none d-md-block">
        <h2 class="text-h5 primary--text py-4">{{ $t('polandOffice.PLANS') }}</h2>
        <ul>
          <li class="py-2"><b>{{ $t('polandOffice.BULLET1') }}</b></li>
          <li class="py-2"><b>{{ $t('polandOffice.BULLET2') }}</b></li>
        </ul>
      </v-col>
      <v-col md="6" cols="12" class="pa-4 my-8 d-md-none">
        <h2 class="text-h5 primary--text py-4">{{ $t('polandOffice.PLANS') }}</h2>
        <ul>
          <li class="py-2"><b>{{ $t('polandOffice.BULLET1') }}</b></li>
          <li class="py-2"><b>{{ $t('polandOffice.BULLET2') }}</b></li>
        </ul>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'AboutUs',

    data: () => ({
    }),
  }
</script>
